// Generated by Framer (77e91d3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/vWKrU6jUs";

const cycleOrder = ["XRg3hHiAs", "PUnlhY8I9"];

const serializationHash = "framer-W8PMm"

const variantClassNames = {PUnlhY8I9: "framer-v-1u31z5e", XRg3hHiAs: "framer-v-1hdn92"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "XRg3hHiAs", Mobile: "PUnlhY8I9"}

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, glrC4LtD0: text ?? props.glrC4LtD0 ?? "Discover", QBXrxtYEt: link ?? props.QBXrxtYEt, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XRg3hHiAs"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, glrC4LtD0, QBXrxtYEt, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XRg3hHiAs", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-W8PMm", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1hdn92", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"XRg3hHiAs"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({PUnlhY8I9: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><Link href={QBXrxtYEt} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-1f8alwc"} data-styles-preset={"vWKrU6jUs"}>Discover</motion.a></Link></motion.p></React.Fragment>} className={"framer-hxp7nz"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"ba7h0XqL5"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={glrC4LtD0} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({PUnlhY8I9: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "40px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><Link href={QBXrxtYEt} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-1f8alwc"} data-styles-preset={"vWKrU6jUs"}>Discover</motion.a></Link></motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-W8PMm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-W8PMm .framer-1tidqex { display: block; }", ".framer-W8PMm .framer-1hdn92 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-W8PMm .framer-hxp7nz { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-W8PMm .framer-1hdn92 { gap: 0px; } .framer-W8PMm .framer-1hdn92 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-W8PMm .framer-1hdn92 > :first-child { margin-left: 0px; } .framer-W8PMm .framer-1hdn92 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 61.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PUnlhY8I9":{"layout":["auto","auto"]}}}
 * @framerVariables {"glrC4LtD0":"text","QBXrxtYEt":"link"}
 * @framerImmutableVariables true
 */
const FrameryqDagOwhz: React.ComponentType<Props> = withCSS(Component, css, "framer-W8PMm") as typeof Component;
export default FrameryqDagOwhz;

FrameryqDagOwhz.displayName = "Nav Link - Same Tab";

FrameryqDagOwhz.defaultProps = {height: 30, width: 61.5};

addPropertyControls(FrameryqDagOwhz, {variant: {options: ["XRg3hHiAs", "PUnlhY8I9"], optionTitles: ["Default", "Mobile"], title: "Variant", type: ControlType.Enum}, glrC4LtD0: {defaultValue: "Discover", displayTextArea: false, title: "Text", type: ControlType.String}, QBXrxtYEt: {title: "Link", type: ControlType.Link}} as any)

addFonts(FrameryqDagOwhz, [...sharedStyle.fonts])